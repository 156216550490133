import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from './redux/blockchain/blockchainActions'
import { fetchData } from './redux/data/dataActions'
import * as s from './styles/globalStyles'
import styled from 'styled-components'
import './fonts/Koulen-Regular.ttf';
import './fonts/fonts.css';
import { generateMerkleProof } from './whitelist/merkle-tree';


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input

export const StyledButton = styled.button`
  padding: 15px;
  border-radius: 10px;
  border: none;
  background-color: var(--button-color);
  padding: 15px;
  font-weight: bold;
  color: var(--button-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--button-color);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--button-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`

export const StyledImg = styled.img`
  background-color: var(--accent);
  border-radius: 10%;
  width: 250px;
  float: left;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`

export const StyledLink = styled.a`
  color: var(--scanlink);
  text-decoration: none;
`

function App() {
  let [over,setOver]=React.useState(false);

   let titlestyle={
    color:'',

  }

  if(over){
    titlestyle.color="var(--active-text)";
    titlestyle.fontFamily= 'KoulenRegular';
    titlestyle.position= 'center';
    titlestyle.textAlign= 'center';
    titlestyle.fontSize= 80;
    titlestyle.fontWeight= 'bold';
    titlestyle.color= 'var(--active-text)';
  }
  else{
    titlestyle.color='var(--accent-text)';
    titlestyle.fontFamily= 'KoulenRegular';
    titlestyle.position= 'center';
    titlestyle.textAlign= 'center';
    titlestyle.fontSize= 80;
    titlestyle.fontWeight= 'bold';
    titlestyle.color= 'var(--accent-text)';
  }


  const dispatch = useDispatch()
  const blockchain = useSelector((state) => state.blockchain)
  const data = useSelector((state) => state.data)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`)
  const [mintAmount, setMintAmount] = useState(1)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    SHOW_BACKGROUND: false,
    "WLENABLED": false
  })

  const claimNFTs = () => {
    let cost =0
    if ((CONFIG.WLENABLED == true)) {
      cost = CONFIG.WL_PRICE
    } else {
      cost = CONFIG.PRICE
    }
    let gasLimit = CONFIG.GAS_LIMIT
    let WLENABLED = Boolean(CONFIG.WLENABLED)
    let totalCostWei = String(cost * mintAmount)
    let totalGasLimit = String(gasLimit)
    console.log('Cost: ', totalCostWei)
    console.log('Gas limit: ', totalGasLimit)
    console.log(cost)
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    if (WLENABLED == true) {
      const merkleProof = generateMerkleProof(blockchain.account);
      blockchain.smartContract.methods
      .WhitelistMint(mintAmount, merkleProof)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei, 
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`,
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })   
      
    } else{
      blockchain.smartContract.methods
      .PublicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`,
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }
    }
      
  let maxmintamount = 0  
  if (CONFIG.WLENABLED == true) {
    maxmintamount = 2    
  } else {
    maxmintamount = 25
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1
    if (newMintAmount < 1) {
      newMintAmount = 1
    }
    setMintAmount(newMintAmount)
  }

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1
    if (newMintAmount > maxmintamount) {
      newMintAmount = maxmintamount
    }
    setMintAmount(newMintAmount)
  }

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    getData((data.cost))
  }, [blockchain.account])
   
  let cost =0
    if ((CONFIG.WLENABLED == true)) {
      cost = CONFIG.WL_PRICE
    } else {
      cost = CONFIG.PRICE
    }
  var ETHCost = (cost)/1000000000000000000;

  let warning = 0
  if (CONFIG.WLENABLED == true) {
    warning = "Please note that - Max per wallet limit is 2 and only whitelisted addresses can mint. If u are not whitelisted and try to mint more than 2, the transaction will fail and you will lose gas"
  } else {
    warning = "Please remember you cannot undo this action and you can mint max of 25 per transaction"
  }

  let status = 0
  if (CONFIG.WLENABLED == true) {
    status = "Whitelist Presale"
  } else {
    status = "Public Sale"
  }

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={'center'}
        style={{ padding: 24, backgroundColor: 'var(--primary)' }}
        image={CONFIG.SHOW_BACKGROUND ? '/config/images/bg.png' : null}
      >
                    <s.TextTitle onMouseOver={()=>setOver(true)} 
      onMouseOut={()=>setOver(false)}
              style={
                titlestyle
              }
            >
             Skoolyard Boys Minting Dapp 
            </s.TextTitle>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24, margin: 50 }} test>
        <s.Container flex={1} jc={'center'} ai={'center'}>
              <StyledImg alt={'example'} src={'/config/images/example.gif'} />
            </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={1}
            jc={'left'}
            ai={'left'}
            style={{
              backgroundColor: 'var(--background)',
              padding: 24,
            }}
          >
            {' '}
            <s.TextTitle
              style={{
                position: 'left',
                textAlign: 'left',
                fontSize: 50,
                fontWeight: 'bold',
                fontFamily: 'KoulenRegular',
                color: 'var(--accent-text)',
              }}
            >
             Currently Minted - {data.totalSupply} 
            </s.TextTitle>
            <s.TextTitle
              style={{
                fontFamily: 'KoulenRegular',
                position: 'left',
                textAlign: 'left',
                fontSize: 20,
                fontWeight: 'bold',
                color: 'var(--accent-text)',
              }}
            >
             Max Supply that can be minted is - {CONFIG.MAX_SUPPLY }
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: 'left',
                color: 'var(--primary-text)',
              }}
            > 
              <StyledLink target={'_blank'} style={{fontFamily: 'KoulenRegular',}} href={CONFIG.SCAN_LINK}>
              You can also mint directly from Scan {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: 'left', color: 'var(--accent-text)' }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: 'left',fontFamily: 'KoulenRegular', color: 'var(--accent-text)' }}
                >
                  1 {CONFIG.NFT_NAME} costs {ETHCost}{' '}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: 'left', fontFamily: 'KoulenRegular', color: 'var(--accent-text)' }}
                >
                  Excluding gas fees.
                </s.TextDescription>

                <s.TextDescription
                  style={{ textAlign: 'left', fontFamily: 'KoulenRegular', color: 'var(--scanlink)', fontSize: 30 }}
                >
                  Phase: {status}
                </s.TextDescription>

                <s.SpacerSmall />
                {blockchain.account === '' ||
                blockchain.smartContract === null ? (
                  <s.Container ai={'left'} jc={'left'}>
                    <s.TextDescription
                      style={{
                        
                        textAlign: 'left',
                        color: 'var(--accent-text)',
                        fontFamily: 'KoulenRegular',
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch(connect())
                        getData()
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== '' ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: 'left',
                            color: 'var(--accent-text)',
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: 'left',
                        color: 'var(--accent-text)',
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={'left'} jc={'left'} fd={'row'}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          decrementMintAmount()
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: 'left',
                          color: 'var(--accent-text)',
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          incrementMintAmount()
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={'left'} jc={'left'} fd={'row'}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          claimNFTs()
                          getData()
                        }}
                      >
                        {claimingNft ? 'BUSY' : 'BUY'}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={'center'} ai={'center'} style={{ width: '70%' }}>
          <s.TextDescription
          href={CONFIG.LINKTREE} target={'_blank'} 
            style={{
              textAlign: 'center',
              color: 'var(--accent-text)',
              fontFamily: 'KoulenRegular',
            }}
          >
           
          {warning}
          </s.TextDescription>
          <s.SpacerSmall />
        </s.Container>
      </s.Container>
    </s.Screen>
  )
}
export default App
